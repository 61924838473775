<!--
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-09-03 14:21:23
 * @LastEditors: Zhongyu
 * @LastEditTime: 2021-10-11 14:45:21
-->
<template>
  <div class="install-shipping-address">
    <div class="personal-center-title">
      收货地址
    </div>
    <div class="install-body">
      <div class="install-body-list">
        <div class="line-item" v-for="(item, i) in list" :key="i">
          <div class="default-address">
            <div class="name">
              {{ item.name }}
            </div>
            <div class="address" v-if="item.isDefault == 1">
              默认地址
            </div>
          </div>
          <div class="item-body">
            <div class="item">
              <div class="label">收货人：</div>
              <div class="conetnt">{{ item.name }}</div>
            </div>
            <div class="item">
              <div class="label">所在地区：</div>
              <div class="conetnt">{{ item.address.replace(/,/g,"") }}</div>
            </div>
            <div class="item">
              <div class="label">详细地址：</div>
              <div class="conetnt">{{ item.addressDetail || "-" }}</div>
            </div>
            <div class="item">
              <div class="label">手机号：</div>
              <div class="conetnt">{{ item.phone }}</div>
            </div>
          </div>
          <div class="operation">
            <el-link
              type="primary"
              v-if="item.isDefault != 1"
              @click="openVisibleTip(item.id)"
            >
              设为默认
            </el-link>
            <el-link type="primary" @click="updateAddress(item)">编辑</el-link>
          </div>
          <i class="el-icon-close" @click="openVisibleDel(item.id)"></i>
        </div>
        <div class="operation-add">
          <el-button @click="addAddress" :disabled="list.length > 10">
            新增收货地址
          </el-button>
          <span class="add-desc">最多只能新建{{ maxAdd }}个地址</span>
        </div>
      </div>
    </div>
    <tip-modal
      :visible="visibleTip"
      @close="handleClose"
      @ok="consigneeDefault"
    >
      <span>确定设为默认地址吗？</span>
    </tip-modal>
    <tip-modal :visible="visibleDel" @close="handleClose" @ok="consigneeDelete">
      <span>确定删除该地址吗？</span>
    </tip-modal>
    <operation ref="operation"></operation>
    <!-- <u-pagination :total="total" @change="changePagination"></u-pagination> -->
  </div>
</template>

<script>
import TipModal from "@/components/TipModal";
import operation from "./operation";
export default {
  components: {
    TipModal,
    operation
  },
  data() {
    return {
      maxAdd: 10,
      list: [],
      visibleTip: false,
      visibleDel: false,
      aId: null
    };
  },
  computed: {
    userId() {
      return this.$store.getters.userId;
    }
  },
  mounted() {
    this.getConsigneeList();
  },
  methods: {
    /**
     * 获取地址列表
     */
    getConsigneeList() {
      let params = {
        ...this.params,
        userId: this.userId
      };
      const loading = this.$loading({
        lock: true,
        text: "查询中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.$api
        .getConsigneeList(params)
        .then(res => {
          this.list = res.data;
        })
        .catch(err => {
          this.$message.error(err?.msg);
        })
        .finally(() => {
          loading.close();
        });
    },

    /**
     * 关闭弹框
     */
    handleClose() {
      this.visibleTip = false;
      this.visibleDel = false;
    },

    /**
     * 打开删除二次确认弹框
     */
    openVisibleDel(id) {
      this.aId = id;
      this.visibleDel = true;
    },

    /**
     * 打开二次确认弹框
     */
    openVisibleTip(id) {
      this.aId = id;
      this.visibleTip = true;
    },

    /**
     * 设置为默认地址
     */
    consigneeDefault() {
      // consigneeDefault
      let params = {
        id: this.aId
      };
      const loading = this.$loading({
        lock: true,
        text: "设置中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.$api
        .consigneeDefault(params)
        .then(() => {
          this.$message({
            message: "恭喜您，设置成功！",
            type: "success"
          });
          this.visibleTip = false;
          this.getConsigneeList();
        })
        .catch(err => {
          this.$message.error(err?.msg);
        })
        .finally(() => {
          loading.close();
        });
    },

    /**
     * 删除地址
     */
    consigneeDelete() {
      let params = {
        id: this.aId
      };
      const loading = this.$loading({
        lock: true,
        text: "删除中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.$api
        .consigneeDelete(params)
        .then(() => {
          this.$message({
            message: "恭喜您，删除成功！",
            type: "success"
          });
          this.visibleDel = false;
          this.getConsigneeList();
        })
        .catch(err => {
          this.$message.error(err?.msg);
        })
        .finally(() => {
          loading.close();
        });
    },

    /**
     * 更新地址
     */
    updateAddress(item) {
      this.$refs.operation.init(item.id, { ...item });
    },

    /**
     * 新增地址
     */
    addAddress() {
      if (this.list.length >= this.maxAdd) {
        this.$message.error(`最多只能新建${this.maxAdd}个地址`);
        return;
      }
      this.$refs.operation.init();
    }
  }
};
</script>

<style lang="less">
@import "./index.less";
</style>
